import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NavLink } from 'react-router-dom'

const SectionHeader = ({ title, image, PageLink }) => { 
    useEffect(() => {
        AOS.init();
      }, []);
    return (
        <>
            <header className="header">
                <div className="header__container">
                    <div className="header__container-bg " data-aos="zoom-in-up" data-aos-duration="3500" data-aos-easing="linear">
                        <img src={image} alt="Header background" />
                    </div>
                    <div className="header__content container" data-aos="fade-right" data-aos-duration="1000" data-aos-easing="linear">
                        <h1>{title}</h1>
                        <span className='header_link'>
                            <NavLink to='/'>Home</NavLink>
                            /
                            <NavLink to='#'  className='header_link_a'>{PageLink}</NavLink>
                        </span>
                    </div>
                </div>
            </header>
        </>
    )
}

export default SectionHeader
