import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import SectionHeader from '../../components/SectionHeader'

import HeaderImage from '../../images/sellbuy.jpg'
import Image from '../../images/host01.jpg'
import Image2 from '../../images/section-herder-img.jpeg'

// import { Col, Container, Row } from 'react-bootstrap'
import SouthCity from '../../components/SouthCity';
import { Col, Container, Row } from 'react-bootstrap';


const About = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <SectionHeader title='About' image={HeaderImage} PageLink='About us'></SectionHeader>
            <div className="">
                <SouthCity className='button-none' />
            </div>
            <div className="about-bg">
                <Container className='padding'>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className="about_img">
                                <img src={Image} alt=""  data-aos="fade-right" data-aos-duration="3000" data-aos-easing="linear" />
                                <div className="about_mission" data-aos="fade-right" data-aos-duration="4000" data-aos-easing="linear">
                                    <h4>Our Mission</h4>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.  Esse velit sit dolorum error, pariatur,!
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="about_img">
                                <img src={Image2} alt=""  data-aos="fade-right" data-aos-duration="3000" data-aos-easing="linear" />
                                <div className="about_mission" data-aos="fade-right" data-aos-duration="4000" data-aos-easing="linear">
                                    <h4>Our People</h4>
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.  Esse velit sit dolorum error, pariatur,!
                                    </p>
                                </div>
                            </div>
                        </Col>
                        {/* <Col xs={12} md={6}>
                            <div className="section_head_two" data-aos="fade-left" data-aos-duration="3000" data-aos-easing="linear">
                            <img src={Image} alt=""  data-aos="fade-right" data-aos-duration="3000" data-aos-easing="linear" />
                                <h2>ABOUT FRONTEDA</h2>
                                <div data-aos="fade-left" data-aos-duration="3000" data-aos-easing="linear">
                                    <p className="section_head_two_p">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.consectetur adipisicing elit Alias illo atque itaque,hic!
                                    </p>
                                    <p className="section_head_two_p">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti voluptate ipsa in, corrupti, neque dolorum, quis magni adipisci reiciendis nemo commodi optio! Minus doloribus officiis odio placeat alias, molestias unde soluta in, ipsa cum aspernatur dolore quo minima quisquam nesciunt laboriosam atque excepturi totam voluptatum odit! Ea ullam autem quod voluptatem dolore officia dolorem facilis culpa, libero aut id consequatur maxime qui quos enim, quidem laboriosam praesentium eveniet commodi odit rerum doloremque necessitatibus minus. Exercitationem, iure culpa recusandae dicta commodi sit vel voluptatibus dolores voluptate. Mollitia, eum vel fugit cupiditate molestias impedit non reiciendis, reprehenderit aut quia molestiae, eius assumenda.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="section_head_two" data-aos="fade-left" data-aos-duration="3000" data-aos-easing="linear">
                                <h2>ABOUT FRONTEDA</h2>
                                <div data-aos="fade-left" data-aos-duration="3000" data-aos-easing="linear">
                                    <p className="section_head_two_p">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.consectetur adipisicing elit Alias illo atque itaque,hic!
                                    </p>
                                    <p className="section_head_two_p">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti voluptate ipsa in, corrupti, neque dolorum, quis magni adipisci reiciendis nemo commodi optio! Minus doloribus officiis odio placeat alias, molestias unde soluta in, ipsa cum aspernatur dolore quo minima quisquam nesciunt laboriosam atque excepturi totam voluptatum odit! Ea ullam autem quod voluptatem dolore officia dolorem facilis culpa, libero aut id consequatur maxime qui quos enim, quidem laboriosam praesentium eveniet commodi odit rerum doloremque necessitatibus minus. Exercitationem, iure culpa recusandae dicta commodi sit vel voluptatibus dolores voluptate. Mollitia, eum vel fugit cupiditate molestias impedit non reiciendis, reprehenderit aut quia molestiae, eius assumenda.
                                    </p>
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default About