import React, { useState } from "react";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/sellbuy.jpg";
import { Container, Row } from "react-bootstrap";
import MapComponent from "../../components/MapComponent";

const Communities = ({ onSearch }) => {
  const [query, setQuery] = useState("");

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Pass the search query to the parent component
    onSearch(query);
  };
  return (
    <>
      <SectionHeader
        title="Communities"
        image={HeaderImage}
        PageLink="Communities"
      ></SectionHeader>
      <Container>
        <div
          className="pages_header_title"
          data-aos="zoom-in-up"
          data-aos-duration="1000"
          data-aos-easing="linear"
        >
          <h1>Communities</h1>
        </div>
      </Container>
      <Container>
        <Row>
          <div className="communities">
            <div className="search-bar">
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  value={query}
                  onChange={handleChange}
                  placeholder="Find a Neighborhoods by name"
                />
                <button class="custom_btn2 btn btn-primary" type="submit">
                  Search
                </button>
              </form>
            </div>
            <MapComponent />
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Communities;
