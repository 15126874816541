import React from 'react'
import SectionHeader from '../../components/SectionHeader'
import HeaderImage from '../../images/sellbuy.jpg'
import { Container } from 'react-bootstrap'

const Careers = () => {
    return (
        <>
            <SectionHeader title='Careers' image={HeaderImage} PageLink='careers'></SectionHeader>
            <Container>
                <div className="careers-main"></div>
                <div className="pages_header_title" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-easing="linear">
                    <h1>Careers</h1>
                </div>
                <div className='careers-page'>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In tempus diam a quam semper gravida.
                        Maecenas luctus sit amet magna sit amet semper. Nullam a mattis nibh. Ut vehicula eget erat in auctor.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam erat volutpat.
                        Donec varius risus nec ante mattis euismod. Ut a libero semper, aliquam erat non,
                        posuere eros. Mauris vehicula vel ligula eget elementum. Nunc convallis imperdiet leo eget pharetra.
                        In nisi nunc, commodo et interdum quis, vestibulum ac ante. Proin cursus nunc id tortor mattis congue.
                        Nam blandit sagittis ullamcorper.
                        <br />
                        <br />
                        Sed id sollicitudin risus, vel ultricies elit. Nam venenatis iaculis mauris et rhoncus.
                        Aenean imperdiet, nibh nec vehicula hendrerit, dui nisi laoreet purus, et varius turpis massa a libero.
                        Curabitur et feugiat nulla. Sed dapibus odio nec mattis finibus. Ut ac nisi quam. Fusce et sem odio.
                        Sed eu magna eget lorem ultricies faucibus sed eu ligula. Phasellus eget lacus sed nunc
                        feugiat dictum a et nulla. Sed quis nulla orci. Aenean non arcu eros. Pellentesque vitae velit elit.
                        Proin et eros orci. Aenean augue orci, pretium in rhoncus vel, pulvinar hendrerit ipsum. Duis ut
                        aliquet dolor.
                        <br />
                        <br />
                        Suspendisse vel massa sit amet risus suscipit gravida eget dictum tellus.
                        Duis tempor magna non facilisis cursus. Nulla dolor dolor, feugiat vel est in, sodales
                        semper nisi. Praesent dui nibh, gravida id nulla eget, faucibus viverra urna. Aenean vel ipsum
                        porta, dignissim ante euismod, mattis felis. Quisque dignissim enim eget lorem rutrum, eu hendrerit
                        elit viverra. Nulla quis tempor leo. Sed euismod eros purus, eu faucibus nisi interdum vitae.
                        Aliquam a leo vitae magna porta commodo. Duis sit amet laoreet sem, at venenatis nunc.
                        <br />
                        <br />
                    </p>
                </div>
            </Container>
        </>
    )
}

export default Careers