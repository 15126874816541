import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';



function SectionName({ title, SubTitle }) {
    useEffect(() => {
        AOS.init();
      }, []);

    return (
        <>
            <div className="section_head" data-aos="fade-right" data-aos-duration="3000" data-aos-easing="linear">
                <h1>{title}</h1>
                <p>{SubTitle}</p>
            </div>
        </>
    )
}

export default SectionName