import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import HeroImg from "../images/abcd.jpg";
import Banner2 from "../images/banner-2.jpg";
import Banner3 from "../images/banner-3.jpg";
import Logo2 from "../images/southcity-logo2.png";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import { BsArrowUpCircleFill } from "react-icons/bs";
import { BsArrowDownCircleFill } from "react-icons/bs";

const HeroSlider = () => {
  var settings = {
    fade: true,
    // autoplay: true,
    autoplaySpeed: 6000,
    speed: 1500,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
  };

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="hero-slider Banner_Slider">
        <Slider {...settings}>
          <div className="hero-slider_img_bg">
            <div className="hero_img">
              <img className="hero_img0" src={HeroImg} alt="" />
              <div className="hero_img_text">
                <span>
                  <img src={Logo2} alt="" />
                </span>
                <div className="direction_compass">
                  <p>Find your Direction </p>
                </div>
                <div className="hero_img_text_p">
                  <p>
                    Buying or selling a property or home can be among the most
                    rewarding or challenging experiences ever. Finding the right
                    firm and agent, with the right mindset to help guide you
                    through the process is critical to ensuring a gratifying
                    experience. At South City Realty, your goals are our utmost
                    priority. Regardless of your practical knowledge about real
                    estate, we can tailor a buying or selling solution to help
                    you achieve your objectives. Let us help you find your
                    direction.
                  </p>
                  <p></p>
                </div>
                {/* <div className="hero_img_text_btn">
                  <button className="custom_btn">
                    <Link to="#">
                      <BsArrowUpCircleFill />
                      Buying
                    </Link>
                  </button>
                  <button className="custom_btn">
                    <Link to="#">
                      <BsArrowDownCircleFill />
                      Selling
                    </Link>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="hero-slider_img_bg">
            <div className="hero_img">
              <img className="hero_img0" src={Banner3} alt="" />
              <div className="hero_img_text">
                <span>
                  <img src={Logo2} alt="" />
                </span>
                <div className="direction_compass">
                  <p>Find your Direction </p>
                  {/* <a href="/CompassRose"><img src={Compassrose} alt="" /></a> */}
                </div>
                <div className="hero_img_text_p">
                  <p>
                    Buying or selling a property or home can be among the most
                    rewarding or challenging experiences ever. Finding the right
                    firm and agent, with the right mindset to help guide you
                    through the process is critical to ensuring a gratifying
                    experience. At South City Realty, your goals are our utmost
                    priority. Regardless of your practical knowledge about real
                    estate, we can tailor a buying or selling solution to help
                    you achieve your objectives. Let us help you find your
                    direction.
                  </p>
                  <p></p>
                </div>
                {/* <div className="hero_img_text_btn">
                  <button className="custom_btn">
                    <Link to="#">
                      <BsArrowUpCircleFill />
                      Buying
                    </Link>
                  </button>
                  <button className="custom_btn">
                    <Link to="#">
                      <BsArrowDownCircleFill />
                      Selling
                    </Link>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="hero-slider_img_bg">
            <div className="hero_img">
              <img className="hero_img0" src={Banner2} alt="" />
              <div className="hero_img_text">
                <span>
                  <img src={Logo2} alt="" />
                </span>
                <div className="direction_compass">
                  <p>Find your Direction </p>
                  {/* <a href="/CompassRose"><img src={Compassrose} alt="" /></a> */}
                </div>
                <div className="hero_img_text_p">
                  <p>
                    Buying or selling a property or home can be among the most
                    rewarding or challenging experiences ever. Finding the right
                    firm and agent, with the right mindset to help guide you
                    through the process is critical to ensuring a gratifying
                    experience. At South City Realty, your goals are our utmost
                    priority. Regardless of your practical knowledge about real
                    estate, we can tailor a buying or selling solution to help
                    you achieve your objectives. Let us help you find your
                    direction.
                  </p>
                  <p></p>
                </div>
                {/* <div className="hero_img_text_btn">
                  <button className="custom_btn">
                    <Link to="#">
                      <BsArrowUpCircleFill />
                      Buying
                    </Link>
                  </button>
                  <button className="custom_btn">
                    <Link to="#">
                      <BsArrowDownCircleFill />
                      Selling
                    </Link>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
};

export default HeroSlider;
