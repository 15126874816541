import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import SectionName from "./SectionName";
import Image01 from "../images/featured01.png";
import Image02 from "../images/featured02.png";
import Image03 from "../images/featured03.png";
import { IoLocationOutline } from "react-icons/io5";
import { IoBed } from "react-icons/io5";
import { GiBathtub } from "react-icons/gi";
import { Link } from "react-router-dom";

const FeaturedListing = ({ className }) => {
  const combinedClassName = `custom_btn_3 ${className}`;

  return (
    <>
      <div className="featured-bg">
        <Container>
          <div className="main-featured">
            <SectionName title="FEATURED LISTINGS" SubTitle="" />
            <div className="featured-all-cards">
              <Card className="featured-card">
                <Card.Img
                  className="featured-card-img"
                  variant="top"
                  src={Image01}
                />
                <Card.Body>
                  <Card.Title className="featured-title">
                    Your heading here
                  </Card.Title>
                  <Card.Text className="featured-text">
                    <IoLocationOutline />
                    <p></p>
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted featured-footer">
                  <span>
                    <IoBed /> 4 bed
                  </span>
                  <span>
                    <GiBathtub /> 2 bath
                  </span>
                </Card.Footer>
              </Card>
              <Card className="featured-card">
                <Card.Img
                  className="featured-card-img"
                  variant="top"
                  src={Image02}
                />
                <Card.Body>
                  <Card.Title className="featured-title">
                    Your heading here
                  </Card.Title>
                  <Card.Text className="featured-text">
                    <IoLocationOutline />
                    <p></p>
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted featured-footer">
                  <span>
                    <IoBed /> 4 bed
                  </span>
                  <span>
                    <GiBathtub /> 2 bath
                  </span>
                </Card.Footer>
              </Card>
              <Card className="featured-card">
                <Card.Img
                  className="featured-card-img"
                  variant="top"
                  src={Image03}
                />
                <Card.Body>
                  <Card.Title className="featured-title">
                    Your heading here
                  </Card.Title>
                  <Card.Text className="featured-text">
                    <IoLocationOutline />
                    <p></p>
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted featured-footer">
                  <span>
                    <IoBed /> 4 bed
                  </span>
                  <span>
                    <GiBathtub /> 2 bath
                  </span>
                </Card.Footer>
              </Card>
              <Card className="featured-card">
                <Card.Img
                  className="featured-card-img"
                  variant="top"
                  src={Image01}
                />
                <Card.Body>
                  <Card.Title className="featured-title">
                    Your heading here
                  </Card.Title>
                  <Card.Text className="featured-text">
                    <IoLocationOutline />
                    <p></p>
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted featured-footer">
                  <span>
                    <IoBed /> 4 bed
                  </span>
                  <span>
                    <GiBathtub /> 2 bath
                  </span>
                </Card.Footer>
              </Card>
              <Card className="featured-card">
                <Card.Img
                  className="featured-card-img"
                  variant="top"
                  src={Image02}
                />
                <Card.Body>
                  <Card.Title className="featured-title">
                    Your heading here
                  </Card.Title>
                  <Card.Text className="featured-text">
                    <IoLocationOutline />
                    <p></p>
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted featured-footer">
                  <span>
                    <IoBed /> 4 bed
                  </span>
                  <span>
                    <GiBathtub /> 2 bath
                  </span>
                </Card.Footer>
              </Card>
              <Card className="featured-card">
                <Card.Img
                  className="featured-card-img"
                  variant="top"
                  src={Image03}
                />
                <Card.Body>
                  <Card.Title className="featured-title">
                    Your heading here
                  </Card.Title>
                  <Card.Text className="featured-text">
                    <IoLocationOutline />
                    <p></p>
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted featured-footer">
                  <span>
                    <IoBed /> 4 bed
                  </span>
                  <span>
                    <GiBathtub /> 2 bath
                  </span>
                </Card.Footer>
              </Card>
            </div>
            <div className="view-all-btn">
              <Link to="/Featured">
                <Button className={combinedClassName}>View All Listings</Button>
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default FeaturedListing;
