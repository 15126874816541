import React from "react";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/sellbuy.jpg";
import { Card, Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import Slider from "react-slick/lib/slider";
import Image01 from "../../images/host01.jpg";
import Image02 from "../../images/sales.01.jpg";
import Image03 from "../../images/service03.png";
import Read from "../../images/read-more.png";
import SectionName from "../../components/SectionName";

const SellingGuide = ({ className }) => {
  const combinedClassName = `our-services ${className}`;

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    slideToScroll: 1,
    speed: 500,
    arrow: true,
  };
  return (
    <>
      <SectionHeader
        title="Sellers Guide"
        image={HeaderImage}
        PageLink="Sellers Guide"
      ></SectionHeader>
      <Container>
        <div
          className="pages_header_title"
          data-aos="zoom-in-up"
          data-aos-duration="1000"
          data-aos-easing="linear"
        >
          <h1>Seller’s Guide</h1>
        </div>
        <div className="home-halue">
          <p className="guide_text">
            <strong>Prepare Your Home for Sale</strong>
          </p>
          <ul>
            <li>
              <strong>Clean and Organize</strong>: Remove personal items and
              extra furniture to make your home look open and inviting.
            </li>
            <li>
              <strong>Maintenance and Upgrades </strong>: Fix any obvious
              repairs and consider doing upgrades that can increase the value of
              your home, such as bathroom or kitchen upgrades.
            </li>
            <li>
              <strong>Curb Appeal</strong>: This is a major factor in a
              successful home sale, so you may need to repaint the exterior or
              clean the siding, plant new flowers and make sure you're keeping
              the lawn short, clean and alive.
            </li>
            <li>
              <strong>Staging</strong>: This process may involve moving your own
              furniture to storage and renting simpler pieces, or it may simply
              mean moving a few chairs around and decluttering. The point
              of staging your home is to help the key rooms – kitchen, living
              room, primary bedroom and primary bathroom – appeal to as many
              potential buyers as possible, rather than reflect your own style.
            </li>
          </ul>
          <p className="guide_text">
            <strong>Pricing</strong>
          </p>
          <ul>
            <li>
              <strong>Neighborhood Comparison</strong>: Research recent home
              sales in your surrounding area to understand current market value.
            </li>
            <li>
              <strong>Professional Appraisal</strong>: Consider hiring a
              professional appraiser to get an accurate valuation of your home.
            </li>
            <li>
              <strong>Competitive Pricing</strong>: Set a competitive asking
              price that attracts buyers but also reflects the value of your
              home. Rather than what you think the house is worth, the asking
              price should be based on what similar houses nearby have recently
              sold for and what makes your home stand out compared to them.
            </li>
          </ul>
          <p className="guide_text">
            <strong>Market Your Property</strong>
          </p>
          <ul>
            <li>
              <strong>Professional Photos and Videos</strong>: Invest in
              professional photography and videography to best highlight your
              home.
            </li>
            <li>
              <strong>Online Listings</strong>: List your property on popular
              real estate websites and social media platforms to ensure maximum
              exposure.
            </li>
            <li>
              <strong>Open Houses and Showings</strong>: Host open houses and
              private showings to give potential buyers a chance to see your
              home in person.
            </li>
          </ul>
          <p className="guide_text">
            <strong>Choose a Listing Agent</strong>
          </p>
          <ul>
            <li>
              <strong>Find a Reputable Agent</strong>: Less than 10% of homes
              are sold by the owner. Given this statistic, having a real estate
              agent with knowledge, experience, and skill can be crucial to
              maximizing your selling price and ensuring a good experience.
            </li>
            <li>
              <strong>Marketing Plan</strong>: Work with your agent to create a
              marketing plan tailored to your specific property.
            </li>
            <li>
              <strong>Negotiation</strong>: Your agent will help you negotiate
              offers and navigate the closing process.
            </li>
          </ul>
          <p className="guide_text">
            <strong>Review Offers and Negotiate</strong>
          </p>
          <ul>
            <li>
              <strong>Evaluate Offers</strong>: Review all offers very
              carefully, considering not just the price but also the terms and
              contingencies.
            </li>
            <li>
              <strong>Counteroffers</strong>: Be prepared to make counteroffers
              to get the best possible price for your home.
            </li>
            <li>
              <strong>Accepting an Offer</strong>: Once you accept an offer,
              your agent will help you navigate the next steps.
            </li>
          </ul>
          <p className="guide_text">
            <strong>Closing the Sale</strong>
          </p>
          <ul>
            <li>
              <strong>Home Inspection</strong>: The buyer will likely conduct a
              home inspection. Be prepared to address any arise that might
              arise.
            </li>
            <li>
              <strong>Appraisal</strong>: The buyer’s lender will require an
              appraisal to confirm the home’s value.
            </li>
            <li>
              <strong>Final Walkthrough</strong>: The buyer will do a final
              walkthrough to ensure that the property is in the agreed upon
              condition.
            </li>
            <li>
              <strong>Closing Documents</strong>: This includes signing the
              necessary documents to transfer ownership and receive payment.
            </li>
          </ul>
          <p className="guide_text">
            <strong>Post – Sale Considerations</strong>
          </p>
          <ul>
            <li>
              <strong>Moving Out</strong>: Plan your move and make sure the home
              is clean and empty by the closing date.
            </li>
            <li>
              <strong>Tax Implications</strong>: Understand the tax implications
              of selling your property and consult with a tax professional if
              needed.
            </li>
            <li>
              <strong>Feedback</strong>: Ask your agent for feedback on the
              process to improve future transactions.
            </li>
          </ul>
        </div>
        <p>
          Selling a home can be overwhelming and a huge undertaking, but with
          careful planning and the right real estate agent, you can achieve a
          successful sale. If you have any specific questions or need further
          assistance, feel free to ask!
        </p>
      </Container>
      <div className="guide_process">
        <SectionName title="The Process" />
      </div>
      <Container>
        <Row>
          <Col md={12}>
            <div className="cards">
              <div className="main_our-services">
                <div className="slider-container">
                  <div className="our-services-cards">
                    <Slider {...settings}>
                      <Card className="our-services-card">
                        <Card.Img
                          className="our-services-img"
                          variant="top"
                          src={Image01}
                        />
                        <Card.Body className="our-services-card-text">
                          <h4>Host Showing</h4>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incidid
                          </p>
                          <Link to="#" className="our-services-read-more">
                            <img
                              src={Read}
                              alt="read more"
                              className="our-services-read-more-img"
                            />
                            Read More
                          </Link>
                        </Card.Body>
                      </Card>
                      <Card className="our-services-card">
                        <Card.Img
                          className="our-services-img"
                          variant="top"
                          src={Image02}
                        />
                        <Card.Body className="our-services-card-text">
                          <h4>Sale An Agent</h4>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incidid
                          </p>
                          <Link to="#" className="our-services-read-more">
                            <img
                              src={Read}
                              alt="read more"
                              className="our-services-read-more-img"
                            />
                            Read More
                          </Link>
                        </Card.Body>
                      </Card>
                      <Card className="our-services-card">
                        <Card.Img
                          className="our-services-img"
                          variant="top"
                          src={Image03}
                        />
                        <Card.Body className="our-services-card-text">
                          <h4>Prep Home For Listing</h4>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incidid
                          </p>
                          <Link to="#" className="our-services-read-more">
                            <img
                              src={Read}
                              alt="read more"
                              className="our-services-read-more-img"
                            />
                            Read More
                          </Link>
                        </Card.Body>
                      </Card>
                      <Card className="our-services-card">
                        <Card.Img
                          className="our-services-img"
                          variant="top"
                          src={Image01}
                        />
                        <Card.Body className="our-services-card-text">
                          <h4>List House For Sale</h4>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incidid
                          </p>
                          <Link to="#" className="our-services-read-more">
                            <img
                              src={Read}
                              alt="read more"
                              className="our-services-read-more-img"
                            />
                            Read More
                          </Link>
                        </Card.Body>
                      </Card>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <div
            className="pages_header_title"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            <h1>Additional Information</h1>
          </div>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Home Selling Costs</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Home Preparation and Staging</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Relocation Assistance</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                What Lorem ipsum dolor sit amet?
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
      </Container>
      <Container>
        <Row>
          <div
            className="pages_header_title"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            <h1>Stay Up To Date</h1>
          </div>
          <div className="home-halue">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In tempus
              diam a quam semper gravida. Maecenas luctus sit amet magna sit
              amet semper. Nullam a mattis nibh. Ut vehicula eget erat in
              auctor. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SellingGuide;
