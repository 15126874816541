// actions.js
export const SORT_BY_PRICE = 'SORT_BY_PRICE';
export const SET_ITEMS_PER_PAGE = 'SET_ITEMS_PER_PAGE';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';

export const sortByPrice = (order) => ({
  type: SORT_BY_PRICE,
  payload: order // 'asc' or 'desc'
});

export const setItemsPerPage = (itemsPerPage) => ({
  type: SET_ITEMS_PER_PAGE,
  payload: itemsPerPage
});


export const selectCategory = (category) => ({
  type: SELECT_CATEGORY,
  payload: category
});