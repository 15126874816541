import React from "react";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/sellbuy.jpg";
import FeaturedListing from "../../components/FeaturedListing";
import Market from "../../components/Market";
import { Container } from "react-bootstrap";

const Featured = () => {
  return (
    <>
      <SectionHeader
        title="Featured"
        image={HeaderImage}
        PageLink="featured"
      ></SectionHeader>
      <Container>
        <FeaturedListing className="button-none" />
        <Market />
      </Container>
    </>
  );
};

export default Featured;
