import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/sellbuy.jpg";

const SearchForm = () => {
  const [formData, setFormData] = useState({
    userName: "",
    office: "",
    language: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Search criteria:", formData);
    // Add your search logic here
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <SectionHeader
        title="Agent"
        image={HeaderImage}
        PageLink="Agent"
      ></SectionHeader>
      <section>
        <div
          className="pages_header_title"
          data-aos="zoom-in-up"
          data-aos-duration="1000"
          data-aos-easing="linear"
        >
          <h1>Our Agents</h1>
        </div>
        <div className="form_all_input">
          <form onSubmit={handleSubmit}>
            <div
              className="form_input"
              data-aos="zoom-in-up"
              data-aos-duration="1000"
              data-aos-easing="linear"
            >
              <input
                type="text"
                id="agentName"
                name="agentName"
                value={formData.userName}
                onChange={handleChange}
                placeholder="Agent Name"
              />
            </div>
            <div
              className="form_input"
              data-aos="zoom-in-up"
              data-aos-duration="1000"
              data-aos-easing="linear"
            >
              <select
                id="office"
                name="office"
                value={formData.office}
                onChange={handleChange}
              >
                <option value="">Find By Office</option>
                <option value="new-york">New York</option>
                <option value="san-francisco">San Francisco</option>
                <option value="london">London</option>
              </select>
            </div>
            <div
              className="form_input"
              data-aos="zoom-in-up"
              data-aos-duration="1000"
              data-aos-easing="linear"
            >
              <select
                id="language"
                name="language"
                value={formData.language}
                onChange={handleChange}
              >
                <option value="">Spoken Language</option>
                <option value="english">English</option>
                <option value="spanish">Spanish</option>
                <option value="french">French</option>
              </select>
            </div>
            <button type="submit">Search</button>
          </form>
        </div>
      </section>
    </>
  );
};

export default SearchForm;
