import React from "react";
import { Button, Card, Container } from "react-bootstrap";
import SectionName from "./SectionName";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import Image01 from "../images/service01.jpg";
import Image03 from "../images/service03.png";
import Image04 from "../images/service04.jpg";
import Image05 from "../images/service05.jpg";
import Image06 from "../images/service06.png";
import Read from "../images/read-more.png";

const OurServices = ({ className }) => {
  const combinedClassName = `our-services ${className}`;
  const combinedClassName1 = `custom_btn_3 ${className}`;

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    slideToScroll: 1,
    speed: 500,
    arrow: true,
  };
  return (
    <>
      <Container>
        <div className={combinedClassName}>
          <SectionName title="RELATED SERVICES" SubTitle="" />
        </div>
        <div className="row">
          <div className="our-services-row">
            {/* <div className="col-12 col-lg-3">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item best_sell_icon" role="presentation">
                  <button
                    class="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-all"
                    type="button"
                    role="tab"
                    aria-controls="pills-all"
                    aria-selected="true"
                  >
                    All
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-selling"
                    type="button"
                    role="tab"
                    aria-controls="pills-selling"
                    aria-selected="false"
                  >
                    Buying
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-buying"
                    type="button"
                    role="tab"
                    aria-controls="pills-buying"
                    aria-selected="false"
                  >
                    Selling
                  </button>
                </li>
              </ul>
            </div> */}
            <div className="col-12 col-lg-4">
              <Link to="/services">
                <Button className={combinedClassName1}>All Services</Button>
              </Link>
            </div>
          </div>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-all"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabindex="0"
            >
              <div className="cards">
                <div className="main_our-services">
                  <div className="slider-container">
                    <div className="our-services-cards">
                      <Slider {...settings}>
                        <Card className="our-services-card">
                          <Card.Img
                            className="our-services-img"
                            variant="top"
                            src={Image05}
                          />
                          <Card.Body className="our-services-card-text">
                            <h4>Rental and Community Management</h4>
                            <p></p>
                            <Link
                              to="/RentalManagement"
                              className="our-services-read-more"
                            >
                              <img
                                src={Read}
                                alt="read more"
                                className="our-services-read-more-img"
                              />
                              Read More
                            </Link>
                          </Card.Body>
                        </Card>
                        <Card className="our-services-card">
                          <Card.Img
                            className="our-services-img"
                            variant="top"
                            src={Image06}
                          />
                          <Card.Body className="our-services-card-text">
                            <h4>Mortgage resources</h4>
                            <p></p>
                            <Link
                              to="/MortgageResources"
                              className="our-services-read-more"
                            >
                              <img
                                src={Read}
                                alt="read more"
                                className="our-services-read-more-img"
                              />
                              Read More
                            </Link>
                          </Card.Body>
                        </Card>
                        <Card className="our-services-card">
                          <Card.Img
                            className="our-services-img"
                            variant="top"
                            src={Image03}
                          />
                          <Card.Body className="our-services-card-text">
                            <h4>Title Insurance and Settlement resources</h4>
                            <p></p>
                            <Link
                              to="/TitleInsurance"
                              className="our-services-read-more"
                            >
                              <img
                                src={Read}
                                alt="read more"
                                className="our-services-read-more-img"
                              />
                              Read More
                            </Link>
                          </Card.Body>
                        </Card>
                        <Card className="our-services-card">
                          <Card.Img
                            className="our-services-img"
                            variant="top"
                            src={Image01}
                          />
                          <Card.Body className="our-services-card-text">
                            <h4>Construction and Remodeling</h4>
                            <p></p>
                            <Link
                              to="/Construction"
                              className="our-services-read-more"
                            >
                              <img
                                src={Read}
                                alt="read more"
                                className="our-services-read-more-img"
                              />
                              Read More
                            </Link>
                          </Card.Body>
                        </Card>
                        <Card className="our-services-card">
                          <Card.Img
                            className="our-services-img"
                            variant="top"
                            src={Image04}
                          />
                          <Card.Body className="our-services-card-text">
                            <h4>Insurance</h4>
                            <p></p>
                            <Link
                              to="/Insurance"
                              className="our-services-read-more"
                            >
                              <img
                                src={Read}
                                alt="read more"
                                className="our-services-read-more-img"
                              />
                              Read More
                            </Link>
                          </Card.Body>
                        </Card>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-selling"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabindex="0"
            >
              ...
            </div>
            <div
              class="tab-pane fade"
              id="pills-buying"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
              tabindex="0"
            >
              ...
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default OurServices;
