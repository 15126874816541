import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Image from "../images/southCity-img.png";
import Logo from "../images/southcity-logo.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const SouthCity = ({ className }) => {
  const combinedClassName = `custom_btn_2 ${className}`;

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="main_south_city">
        <Container>
          <Row className="south_city">
            <Col lg="5">
              <div className="south_city_left">
                <img
                  src={Image}
                  className="south_city_img"
                  alt="south city"
                  data-aos="fade-right"
                  data-aos-duration="3000"
                  data-aos-easing="linear"
                />
              </div>
            </Col>
            <Col lg="5">
              <div
                className="south_city_right"
                data-aos="fade-left"
                data-aos-duration="3000"
                data-aos-easing="linear"
              >
                <span className="welcome-south">Welcome to </span>
                <div className="south_city_logo">
                  <img src={Logo} alt="" />
                </div>
                <p className="south_city_text"></p>
                <Link to="/about">
                  <Button className={combinedClassName}>
                    About South City Realty
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SouthCity;
