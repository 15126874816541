import React from "react";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/sellbuy.jpg";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MapComponent from "../../components/MapComponent";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import OfficeImg from "../../images/house1.jpg";

const MapSearch = () => {
  return (
    <>
      <SectionHeader
        title="Map Search"
        image={HeaderImage}
        PageLink="Map Search"
      ></SectionHeader>
      <Container>
        <div
          className="pages_header_title"
          data-aos="zoom-in-up"
          data-aos-duration="1000"
          data-aos-easing="linear"
        >
          <h1>Map Search</h1>
        </div>
      </Container>
      <Container>
        <Row>
          <div className="map">
            <MapComponent />
          </div>
          <div className="offices-map">
            <Col md={4}>
              <div className="map-img">
                <img src={OfficeImg} alt="#" />
              </div>
            </Col>
            <Col md={4}>
              <div className="offices-adress">
                <FontAwesomeIcon icon={faLocationDot} />
                <h2>Address</h2>
              </div>
              <div className="offices-adress-p">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                  tempus diam a quam semper gravida. Maecenas
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="offices-adress">
                <FontAwesomeIcon icon={faPhone} />
                <h2>Contact Information</h2>
              </div>
              <div className="offices-adress-p">
                <p>
                  (156)485-1456
                  <br />
                  Loremipsum@gmail.com
                  <br />
                </p>
              </div>
            </Col>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MapSearch;
