import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/sellbuy.jpg";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Contact = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    comment: "",
    agent: "",
    phone: "",
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data submitted:", formData);
    console.log("Checkbox is checked:", isChecked);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <SectionHeader
        title="Contact Us"
        image={HeaderImage}
        PageLink="contact us"
      ></SectionHeader>
      <section>
        <Container>
          <Row>
            <div className="main-contactus">
              <Col md={6}>
                <div className="contact_left_content">
                  <h2>Give Us A Call</h2>
                  <div className="contact-us_text">
                    Direct:<span>(615- 618-9414)</span>
                    <p>1823 Stryker Pl. Brentwood, TN 37027</p>
                    <div className="Social_icons">
                      <FontAwesomeIcon
                        icon={faFacebook}
                        style={{ color: "#053b6e" }}
                      />
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        style={{ color: "#053b6e" }}
                      />
                      <FontAwesomeIcon
                        icon={faYoutube}
                        style={{ color: "#053b6e" }}
                      />
                      <FontAwesomeIcon
                        icon={faInstagram}
                        style={{ color: "#053b6e" }}
                      />
                      <FontAwesomeIcon
                        icon={faTwitter}
                        style={{ color: "#053b6e" }}
                      />
                    </div>
                  </div>
                  <div className="visit_office">
                    <h2>Visit An Office</h2>
                    <Dropdown className="custom-dropdown">
                      <Dropdown.Toggle
                        variant="color-primary"
                        id="dropdown-basic"
                      >
                        Choose An Office
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          Another action
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          Something else
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div
                  className="pages_header_title"
                  data-aos="zoom-in-up"
                  data-aos-duration="1000"
                  data-aos-easing="linear"
                ></div>
                <div className="contact-form">
                  <h1>Let's Connect</h1>
                  <form onSubmit={handleSubmit}>
                    <div
                      className="form_input"
                      data-aos="zoom-in-up"
                      data-aos-duration="1000"
                      data-aos-easing="linear"
                    >
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div
                      className="form_input"
                      data-aos="zoom-in-up"
                      data-aos-duration="1000"
                      data-aos-easing="linear"
                    >
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div
                      className="form_input"
                      data-aos="zoom-in-up"
                      data-aos-duration="1000"
                      data-aos-easing="linear"
                    >
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div
                      className="form_input"
                      data-aos="zoom-in-up"
                      data-aos-duration="1000"
                      data-aos-easing="linear"
                    >
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="check-box">
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                      </label>
                      <p>Receive Messages And Notifications Via SMS</p>
                    </div>
                    <h2>Choose An Agent</h2>
                    <div
                      className="form_input"
                      data-aos="zoom-in-up"
                      data-aos-duration="1000"
                      data-aos-easing="linear"
                    >
                      <input
                        type="name"
                        id="agent"
                        name="agent"
                        value={formData.agent}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <h2>Servcies Of Interest </h2>
                    <div className="check-box-main">
                      <div className="check-box1">
                        <label>
                          <input
                            type="checkbox"
                            name="checkbox1"
                            checked={formData.checkbox1}
                            onChange={handleChange}
                          />
                        </label>
                        <p>buying</p>
                      </div>
                      <div className="check-box1">
                        <label>
                          <input
                            type="checkbox"
                            name="checkbox2"
                            checked={formData.checkbox2}
                            onChange={handleChange}
                          />
                        </label>
                        <p>career</p>
                      </div>
                      <div className="check-box1">
                        <label>
                          <input
                            type="checkbox"
                            name="checkbox3"
                            checked={formData.checkbox3}
                            onChange={handleChange}
                          />
                        </label>
                        <p>foreclosure</p>
                      </div>
                    </div>
                    <div className="check-box-main2">
                      <div className="check-box1">
                        <label>
                          <input
                            type="checkbox"
                            name="checkbox4"
                            checked={formData.checkbox4}
                            onChange={handleChange}
                          />
                        </label>
                        <p>selling</p>
                      </div>
                      <div className="check-box1">
                        <label>
                          <input
                            type="checkbox"
                            name="checkbox5"
                            checked={formData.checkbox5}
                            onChange={handleChange}
                          />
                        </label>
                        <p>mortgage</p>
                      </div>
                    </div>
                    <div
                      className="form_input"
                      data-aos="zoom-in-up"
                      data-aos-duration="1000"
                      data-aos-easing="linear"
                    >
                      <textarea
                        rows="4"
                        cols="50"
                        id="comment"
                        name="comment"
                        placeholder="Message"
                        value={formData.comment}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <button type="submit">CONTACT NOW</button>
                  </form>
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Contact;
