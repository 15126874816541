const INIT_STATE = {
    carts: []
};


export const cartreducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case "ADD_CART":
            const IteamIndex = state.carts.findIndex((iteam) => iteam.id === action.payload.id);
            if (IteamIndex !== -1) {
                state.carts[IteamIndex].qnty += 1
                return {
                    ...state,
                    carts: [...state.carts]
                }
            } else {
                const temp = { ...action.payload, qnty: 1 }
                return {
                    ...state,
                    carts: [...state.carts, temp]
                }
            }

        case "RMV_CART":
            const data = state.carts.filter((el) => el.id !== action.payload);
            // console.log(data);
            return {
                ...state,
                carts: data
            }
            case "RMV_ONE":
                const itemIndex = state.carts.findIndex((item) => item.id === action.payload.id);
                if (state.carts[itemIndex].qnty > 1) {
                    const updatedCarts = state.carts.map((item, index) => {
                        if (index === itemIndex) {
                            return {
                                ...item,
                                qnty: item.qnty - 1
                            };
                        }
                        return item;
                    });
                    return {
                        ...state,
                        carts: updatedCarts
                    };
                } else {
                    // If quantity is 1, remove the item from the cart
                    const updatedCarts = state.carts.filter((item) => item.id !== action.payload.id);
                    return {
                        ...state,
                        carts: updatedCarts
                    };
                }
            
        // case "RMV_ONE":
        //     const IteamIndex_dec = state.carts.findIndex((iteam) => iteam.id === action.payload.id);

        //     if (state.carts[IteamIndex_dec].qnty >= 1) {
        //         const dltiteams = state.carts[IteamIndex_dec].qnty -= 1
        //         console.log([...state.carts, dltiteams]);

        //         return {
        //             ...state,
        //             carts: [...state.carts]
        //         };
        //     } else if (state.carts[IteamIndex_dec].qnty === 1) {
        //         const data = state.carts.filter((el) => el.id !== action.payload);

        //         return {
        //             ...state,
        //             carts: data
        //         };
        //     }

        default:
            return state;
    }
};