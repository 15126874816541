import React from "react";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/sellbuy.jpg";
import { Col, Container, Row } from "react-bootstrap";
import OfficeImg from "../../images/featured01.png";

const Referrals = () => {
  return (
    <>
      <SectionHeader
        title="Referrals"
        image={HeaderImage}
        PageLink="Referrals"
      ></SectionHeader>
      <div
        className="pages_header_title"
        data-aos="zoom-in-up"
        data-aos-duration="1000"
        data-aos-easing="linear"
      >
        <h1>Referrals</h1>
      </div>
      <section className="Referrals">
        <Container>
          <Row>
            <div className="Referrals-bottom">
              <Col md={6}>
                <div className="about_img">
                  <img
                    src={OfficeImg}
                    alt=""
                    data-aos="fade-right"
                    data-aos-duration="3000"
                    data-aos-easing="linear"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="Referrals-adress">
                  <div
                    data-aos="fade-left"
                    data-aos-duration="3000"
                    data-aos-easing="linear"
                  >
                    <h2>Lorem ipsum</h2>
                    <p>
                      <ul>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
                <div className="Referrals-adress-p"></div>
              </Col>
            </div>
          </Row>
          <Row>
            <div className="Referrals-bottom2">
              <Col md={6}>
                <div className="Referrals-adress">
                  <div
                    data-aos="fade-right"
                    data-aos-duration="3000"
                    data-aos-easing="linear"
                  >
                    <h2>Lorem ipsum</h2>
                    <p>
                      <ul>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
                <div className="Referrals-adress-p"></div>
              </Col>
              <Col md={6}>
                <div className="about_img">
                  <img
                    src={OfficeImg}
                    alt=""
                    data-aos="fade-left"
                    data-aos-duration="3000"
                    data-aos-easing="linear"
                  />
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Referrals;
