import React from "react";
import { Container, Row, Col, InputGroup, Form, Button } from "react-bootstrap";
import FaceBook from "../images/facebook.png";
import Instagram from "../images/instagram.png";
import Twitter from "../images/twitter.png";
import Whatsapp from "../images/whatsapp.png";
import { Link } from "react-router-dom";
import Logo from "../images/southcity-logo.png";
import { IoCallOutline, IoLocationOutline } from "react-icons/io5";
import { TfiEmail } from "react-icons/tfi";
import { GrLanguage } from "react-icons/gr";

const Footer = () => {
  return (
    <>
      <footer>
        <section className="footer_section">
          <Container>
            <div className="my-container">
              <Row>
                <Col
                  lg={8}
                  data-aos="fade-right"
                  data-aos-duration="2000"
                  data-aos-easing="linear"
                >
                  <div className="news">
                    <h2>Get the Newest Listings</h2>
                    <p>
                      Be the first to know when a home matching your search hits
                      the market
                    </p>
                  </div>
                </Col>
                <Col
                  lg={4}
                  data-aos="fade-left"
                  data-aos-duration="2000"
                  data-aos-easing="linear"
                >
                  <div className="">
                    <InputGroup className="footer_btn">
                      <Button className="custom_btn_white">
                        <Link to="#">SIGNUP NOW</Link>
                      </Button>
                    </InputGroup>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>

        <section className="footer_section2">
          <Container>
            <Row>
              <Col lg={4}>
                <div className="footerlogo">
                  <img className="Logo" src={Logo} alt="" />
                </div>
              </Col>
              <Col lg={4}>
                <div className="footer_logo_section">
                  <div className="question_form_content">
                    <h3>CONTACT US</h3>
                    <p></p>
                    <div className="question_form_content_us">
                      <div className="contact_us_link">
                        <IoCallOutline />
                        <div>
                          <h6>Phone Number</h6>
                          <Link to="">615- 618-9414</Link>
                        </div>
                      </div>
                      <div className="contact_us_link">
                        <TfiEmail />
                        <div>
                          <h6>Email Address</h6>
                          <Link to="">chris@southcityRealtytn.com</Link>
                        </div>
                      </div>
                      <div className="contact_us_link">
                        <GrLanguage />
                        <div>
                          <h6>Website</h6>
                          <Link to="">Southcityrealtytn.com</Link>
                        </div>
                      </div>
                      <div className="contact_us_link">
                        <IoLocationOutline />
                        <div>
                          <h6>Address</h6>
                          <Link to="">
                            1823 Stryker Pl. Brentwood, TN 37027
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col lg={3}>
                <div class="footer-links">
                  <h5>Links</h5>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/featured">Featured</Link>
                    </li>
                    <li>
                      <Link to="#">Top Products</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={3}>
                <div class="footer-links">
                  <h5>Quick Links</h5>
                  <ul>
                    <li>
                      <Link to="#">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="#">Terms & Condition</Link>
                    </li>
                    <li>
                      <Link to="#">Return Policy</Link>
                    </li>
                    <li>
                      <Link to="#">Shipping Policy</Link>
                    </li>
                    <li>
                      <Link to="#">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </Col> */}
              <Col lg={4}>
                <div class="footer-links">
                  <h5>Sign up for exclusive updates</h5>
                  <ul>
                    <li>
                      <Link to="#">
                        Sign up for Market updates, new listings & real estate
                        trends in your area
                      </Link>
                    </li>
                    <li>
                      <InputGroup className="footer_inputs">
                        <Form.Control placeholder="Enter your email address" />
                        <Button className="custom_btn2" id="button-addon2">
                          Submit
                        </Button>
                      </InputGroup>
                    </li>
                  </ul>
                </div>
                <div className="footer_icon">
                  <ul>
                    <li>
                      <Link to="#">
                        <img src={FaceBook} alt="FaceBook" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src={Instagram} alt="Instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src={Twitter} alt="Twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src={Whatsapp} alt="Whatsapp" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              {/* <Col lg={2} className='live-message'>
                  <div class="live-message footer-links">
                    <span><MdMessage /></span>
                    <h5>Chat NOW</h5>
                  </div>
                </Col> */}
            </Row>
          </Container>
        </section>

        <section className="footer_section3">
          <Container>
            <Row>
              <Col sm={12}>
                <p className="footer_section3_p">
                  SOUTH CITY REALTY. All Rights Reserved.
                </p>
              </Col>
              {/* <Col sm={6} className='footer_card' >
                <Link to="/"><img src={Cards} alt="" /></Link>
              </Col > */}
            </Row>
          </Container>
        </section>
      </footer>
    </>
  );
};
export default Footer;
