import React from "react";
import HeroSlider from "../../components/HeroSlider";
import Search from "../../components/Search";
import SouthCity from "../../components/SouthCity";
import SellBuy from "../../components/SellBuy";
import OurServices from "../../components/OurServices";

import FeaturedListing from "../../components/FeaturedListing";
import Question from "../../components/Question";

const Home = () => {
  return (
    <>
      <HeroSlider />
      <Search />
      <SouthCity />
      <SellBuy />
      <OurServices />
      <FeaturedListing />
      <Question />
    </>
  );
};

export default Home;
