import React from "react";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/sellbuy.jpg";
import { Card, Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import Slider from "react-slick/lib/slider";
import Image01 from "../../images/host01.jpg";
import Image02 from "../../images/sales.01.jpg";
import Image03 from "../../images/service03.png";
import Read from "../../images/read-more.png";
import SectionName from "../../components/SectionName";

const BuyingGuide = ({ className }) => {
  const combinedClassName = `our-services ${className}`;

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    slideToScroll: 1,
    speed: 500,
    arrow: true,
  };
  return (
    <>
      <SectionHeader
        title="Buyers Guide"
        image={HeaderImage}
        PageLink="Buyers Guide"
      ></SectionHeader>
      <Container>
        <div
          className="pages_header_title"
          data-aos="zoom-in-up"
          data-aos-duration="1000"
          data-aos-easing="linear"
        >
          <h1>Buyer’s Guide</h1>
        </div>
        <div className="home-halue">
          <p className="guide_text">
            <strong>Determine Your Budget</strong>
          </p>
          <ul>
            <li>
              <strong>Assess Your Finances</strong>: Review your savings,
              income, and expenses to determine how much you can afford to spend
              on a new home.
            </li>
            <li>
              <strong>Get Pre-Approved</strong>: Obtain a mortgage pre-approval
              to understand your borrowing capacity and show sellers you’re a
              serious buyer.
            </li>
          </ul>
          <p className="guide_text">
            <strong>Identify Your Needs and Wants</strong>
          </p>
          <ul>
            <li>
              <strong>List Priorities</strong>: Make a list of must-haves (e.g.,
              number of bedrooms, location) and nice-to-haves (e.g., a pool,
              large yard).
            </li>
            <li>
              <strong>Research Neighborhoods</strong>: Explore different areas
              to find the ones that best match your lifestyle and budget.
            </li>
          </ul>
          <p className="guide_text">
            <strong>Find a Real Estate Agent</strong>
          </p>
          <ul>
            <li>
              <strong>Choose Wisely</strong>: Select an experienced agent who
              knows the local market and understands your needs.
            </li>
            <li>
              <strong>Communicate Clearly</strong>: Share your priorities and
              budget with your agent to help them find fitting properties.
            </li>
          </ul>
          <p className="guide_text">
            <strong>Start House Hunting</strong>
          </p>
          <ul>
            <li>
              <strong>Online Listings</strong>: Browse real estate websites and
              apps to see what’s available in your desired location.
            </li>
            <li>
              <strong>Attend Open Houses</strong>: Visit open houses to get a
              feel for different properties and neighborhoods.
            </li>
            <li>
              <strong>Private Showings</strong>: Schedule private showings for
              homes that interest you.
            </li>
          </ul>
          <p className="guide_text">
            <strong>Make an Offer</strong>
          </p>
          <ul>
            <li>
              <strong>Evaluate the Property</strong>: Consider the home’s
              condition, price, and how it compares to similar properties.
            </li>
            <li>
              <strong>Submit an Offer</strong>: Work with your agent to submit a
              competitive offer, including any contingencies (e.g., home
              inspection, financing).
            </li>
            <li>
              <strong>Negotiate</strong>: Be prepared to negotiate with the
              seller to reach a mutually agreeable price.
            </li>
          </ul>
          <p className="guide_text">
            <strong>Conduct Due Diligence</strong>
          </p>
          <ul>
            <li>
              <strong>Home Inspection</strong>: Hire a professional inspector to
              assess the property’s condition and identify any issues.
            </li>
            <li>
              <strong>Appraisal</strong>: Your lender will require an appraisal
              to ensure the home’s value matches the loan amount.
            </li>
            <li>
              <strong>Review Documents</strong>: Carefully review all documents,
              including the purchase agreement and disclosures.
            </li>
          </ul>
          <p className="guide_text">
            <strong>Secure Financing</strong>
          </p>
          <ul>
            <li>
              <strong>Finalize Your Loan</strong>: Work with your lender to
              complete the mortgage application and secure financing.
            </li>
            <li>
              <strong>Lock in Your Rate</strong>: Lock in your interest rate to
              protect against market fluctuations.
            </li>
          </ul>
          <p className="guide_text">
            <strong>Close the Deal</strong>
          </p>
          <ul>
            <li>
              <strong>Final Walkthrough</strong>: Conduct a final walkthrough to
              ensure the property is in the agreed-upon condition.
            </li>
            <li>
              <strong>Sign Documents</strong>: Sign the necessary documents to
              finalize the purchase.
            </li>
            <li>
              <strong>Transfer Funds</strong>: Arrange for the transfer of funds
              to complete the transaction.
            </li>
          </ul>
          <p className="guide_text">
            <strong>Move In</strong>
          </p>
          <ul>
            <li>
              <strong>Plan Your Move</strong>: Schedule movers and plan the
              logistics of moving into your new home.
            </li>
            <li>
              <strong>Set Up Utilities</strong>: Arrange for utilities and
              services to be transferred to your name.
            </li>
            <li>
              <strong>Enjoy Your New Home</strong>: Celebrate and settle into
              your new space!
            </li>
          </ul>
        </div>
        <p>
          Buying a home is a significant milestone, and with careful planning
          and the right real estate agent, you can make the process smooth and
          enjoyable. If you have any specific questions or need further
          assistance, feel free to ask!
        </p>
      </Container>
      <div className="guide_process">
        <SectionName title="The Process" />
      </div>
      <Container>
        <Row>
          <Col md={12}>
            <div className="cards">
              <div className="main_our-services">
                <div className="slider-container">
                  <div className="our-services-cards">
                    <Slider {...settings}>
                      <Card className="our-services-card">
                        <Card.Img
                          className="our-services-img"
                          variant="top"
                          src={Image01}
                        />
                        <Card.Body className="our-services-card-text">
                          <h4>Home Buying Tips</h4>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incidid
                          </p>
                          <Link to="#" className="our-services-read-more">
                            <img
                              src={Read}
                              alt="read more"
                              className="our-services-read-more-img"
                            />
                            Read More
                          </Link>
                        </Card.Body>
                      </Card>
                      <Card className="our-services-card">
                        <Card.Img
                          className="our-services-img"
                          variant="top"
                          src={Image02}
                        />
                        <Card.Body className="our-services-card-text">
                          <h4>First Time Homebuyer Info</h4>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incidid
                          </p>
                          <Link to="#" className="our-services-read-more">
                            <img
                              src={Read}
                              alt="read more"
                              className="our-services-read-more-img"
                            />
                            Read More
                          </Link>
                        </Card.Body>
                      </Card>
                      <Card className="our-services-card">
                        <Card.Img
                          className="our-services-img"
                          variant="top"
                          src={Image03}
                        />
                        <Card.Body className="our-services-card-text">
                          <h4>Mortgage Calculator </h4>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incidid
                          </p>
                          <Link to="#" className="our-services-read-more">
                            <img
                              src={Read}
                              alt="read more"
                              className="our-services-read-more-img"
                            />
                            Read More
                          </Link>
                        </Card.Body>
                      </Card>
                      <Card className="our-services-card">
                        <Card.Img
                          className="our-services-img"
                          variant="top"
                          src={Image01}
                        />
                        <Card.Body className="our-services-card-text">
                          <h4>List House For Sale</h4>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incidid
                          </p>
                          <Link to="#" className="our-services-read-more">
                            <img
                              src={Read}
                              alt="read more"
                              className="our-services-read-more-img"
                            />
                            Read More
                          </Link>
                        </Card.Body>
                      </Card>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <div
            className="pages_header_title"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            <h1>Additional Information</h1>
          </div>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Home Selling Costs</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Home Preparation and Staging</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Relocation Assistance</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                What Lorem ipsum dolor sit amet?
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
      </Container>
      <Container>
        <Row>
          <div
            className="pages_header_title"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            <h1>Stay Up To Date</h1>
          </div>
          <div className="home-halue">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In tempus
              diam a quam semper gravida. Maecenas luctus sit amet magna sit
              amet semper. Nullam a mattis nibh. Ut vehicula eget erat in
              auctor. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default BuyingGuide;
