import React from 'react'
import OurServices from '../../components/OurServices'
import SectionHeader from '../../components/SectionHeader'
import HeaderImage from '../../images/sellbuy1.jpg'


const Services = () => {
    return (
        <>
            <SectionHeader title='RELATED SERVICES' image={HeaderImage} PageLink='RELATED SERVICES'></SectionHeader>
            <OurServices className='our-services2 button-none'/>
        </>
    )
}

export default Services