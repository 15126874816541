import React from 'react'
import { Container, Row, Col, InputGroup, Form, Button } from 'react-bootstrap'

const Market = () => {
    return (
        <>
            <div className='market-bg'>
                <Container>
                    <Row className='market-row'>
                        <Col lg='4'>
                            <div className='market-right'>
                                <h2>Market insights for your area.</h2>
                                <p></p>
                            </div>
                        </Col>
                        <Col lg='5'>
                            <div className='market-left'>
                                <p>Search for your ZIP CODE's market analysis.</p>
                                <InputGroup className="market_inputs">
                                    <Form.Control placeholder="Search Zip Code" />
                                    <Button className='market-btn' id="button-addon2">Go</Button>
                                </InputGroup>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Market