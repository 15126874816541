import React from "react";
import { Card, Container } from "react-bootstrap";
import Image01 from "../../images/house1.jpg";
import Image02 from "../../images/house2.jpg";
import Image03 from "../../images/house3.jpg";
import HeaderImage from "../../images/sellbuy.jpg";
import { IoLocationOutline } from "react-icons/io5";
import { IoBed } from "react-icons/io5";
import { GiBathtub } from "react-icons/gi";
import SectionHeader from "../../components/SectionHeader";

const HouseSearch = () => {
  return (
    <>
      <SectionHeader
        title="House Search"
        image={HeaderImage}
        PageLink="House Search"
      ></SectionHeader>
      <div
        className="pages_header_title"
        data-aos="zoom-in-up"
        data-aos-duration="1000"
        data-aos-easing="linear"
      >
        <h1>House Search</h1>
      </div>
      <div className="featured">
        <Container>
          <div className="main-featured">
            <div className="featured-all-cards">
              <Card className="featured-card">
                <Card.Img
                  className="featured-card-img"
                  variant="top"
                  src={Image01}
                />
                <Card.Body>
                  <Card.Title className="featured-title">
                    Your heading here
                  </Card.Title>
                  <Card.Text className="featured-text">
                    <IoLocationOutline />
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod
                    </p>
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted featured-footer">
                  <span>
                    <IoBed /> 4 bed
                  </span>
                  <span>
                    <GiBathtub /> 2 bath
                  </span>
                </Card.Footer>
              </Card>
              <Card className="featured-card">
                <Card.Img
                  className="featured-card-img"
                  variant="top"
                  src={Image02}
                />
                <Card.Body>
                  <Card.Title className="featured-title">
                    Your heading here
                  </Card.Title>
                  <Card.Text className="featured-text">
                    <IoLocationOutline />
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod
                    </p>
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted featured-footer">
                  <span>
                    <IoBed /> 4 bed
                  </span>
                  <span>
                    <GiBathtub /> 2 bath
                  </span>
                </Card.Footer>
              </Card>
              <Card className="featured-card">
                <Card.Img
                  className="featured-card-img"
                  variant="top"
                  src={Image03}
                />
                <Card.Body>
                  <Card.Title className="featured-title">
                    Your heading here
                  </Card.Title>
                  <Card.Text className="featured-text">
                    <IoLocationOutline />
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod
                    </p>
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted featured-footer">
                  <span>
                    <IoBed /> 4 bed
                  </span>
                  <span>
                    <GiBathtub /> 2 bath
                  </span>
                </Card.Footer>
              </Card>
              <Card className="featured-card">
                <Card.Img
                  className="featured-card-img"
                  variant="top"
                  src={Image01}
                />
                <Card.Body>
                  <Card.Title className="featured-title">
                    Your heading here
                  </Card.Title>
                  <Card.Text className="featured-text">
                    <IoLocationOutline />
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod
                    </p>
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted featured-footer">
                  <span>
                    <IoBed /> 4 bed
                  </span>
                  <span>
                    <GiBathtub /> 2 bath
                  </span>
                </Card.Footer>
              </Card>
              <Card className="featured-card">
                <Card.Img
                  className="featured-card-img"
                  variant="top"
                  src={Image02}
                />
                <Card.Body>
                  <Card.Title className="featured-title">
                    Your heading here
                  </Card.Title>
                  <Card.Text className="featured-text">
                    <IoLocationOutline />
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod
                    </p>
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted featured-footer">
                  <span>
                    <IoBed /> 4 bed
                  </span>
                  <span>
                    <GiBathtub /> 2 bath
                  </span>
                </Card.Footer>
              </Card>
              <Card className="featured-card">
                <Card.Img
                  className="featured-card-img"
                  variant="top"
                  src={Image03}
                />
                <Card.Body>
                  <Card.Title className="featured-title">
                    Your heading here
                  </Card.Title>
                  <Card.Text className="featured-text">
                    <IoLocationOutline />
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod
                    </p>
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted featured-footer">
                  <span>
                    <IoBed /> 4 bed
                  </span>
                  <span>
                    <GiBathtub /> 2 bath
                  </span>
                </Card.Footer>
              </Card>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HouseSearch;
