import { createStore, combineReducers } from 'redux';
import filterReducers from './reducer/filterReducers';
import { cartreducer } from './reducer/CartReducer';
import authReducer from './reducer/authReducer';

const rootReducer = combineReducers({
  shop: filterReducers,
  // carts: cartReducer
  auth: authReducer,
  cartreducer
});
const userData = JSON.parse(localStorage.getItem('user'));

const initialState = {
  auth: {
    user: userData,
    userId: userData ? userData.id : null,
    error: null,
    isAuthenticated: !!userData
  }
};

const store = createStore(
  rootReducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;

