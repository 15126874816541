import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../images/southcity-logo.png";
import { NavLink, Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

const Header = () => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleToggle = (dropdown) => {
    if (openDropdown === dropdown) {
      setOpenDropdown(null); // Close the dropdown if it's already open
    } else {
      setOpenDropdown(dropdown); // Open the new dropdown
    }
  };

  const handleClose = () => setOpenDropdown(null); // Close all dropdowns

  return (
    <>
      <div className="main_header">
        <Navbar collapseOnSelect expand="lg">
          <Container>
            <Row style={{ alignItems: "center" }}>
              <Col md={2}>
                <Navbar.Brand href="/" className="navbar-logo">
                  <img src={Logo} alt="" />
                </Navbar.Brand>
              </Col>
              <Col md={10}>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="main_navbar">
                    <NavLink to="/" className="nav_link">
                      Home
                    </NavLink>
                    <NavDropdown
                      title="Buying"
                      id="basic-nav-dropdown"
                      className="navs_link"
                      show={openDropdown === "Buying"}
                      onClick={() => handleToggle("Buying")}
                    >
                      <NavDropdown.Item as={Link} to="/PropertySearch">
                        Property Search
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/MapSearch">
                        Map Search
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/HouseSearch">
                        Open House Search
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/BuyersGuide">
                        Buying Guide
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                      title="Selling"
                      id="basic-nav-dropdown"
                      className="navs_link"
                      show={openDropdown === "Selling"}
                      onClick={() => handleToggle("Selling")}
                    >
                      <NavDropdown.Item as={Link} to="/HomeValueEstimator">
                        Home Value Estimator
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/SellersGuide">
                        Selling Guide
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavLink to="/Communities" className="nav_link">
                      Communities
                    </NavLink>
                    <NavDropdown
                      title="Services"
                      id="basic-nav-dropdown"
                      className="navs_link"
                      show={openDropdown === "Services"}
                      onClick={() => handleToggle("Services")}
                    >
                      <NavDropdown.Item as={Link} to="/RentalManagement">
                        Rental and Community Management
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/MortgageResources">
                        Mortgage resources
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/TitleInsurance">
                        Title Insurance and Settlement Resources
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/Construction">
                        Construction and Remodeling
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/Insurance">
                        Insurance
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavLink to="/Agent" className="nav_link">
                      Agents
                    </NavLink>
                    <NavDropdown
                      title="Company"
                      id="basic-nav-dropdown"
                      className="navs_link"
                      show={openDropdown === "Company"}
                      onClick={() => handleToggle("Company")}
                    >
                      <NavDropdown.Item as={Link} to="/about">
                        About us
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/Offices">
                        Offices
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/Referrals">
                        Referrals
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/careers">
                        Careers
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavLink to="contact" className="nav_link">
                      Contact Us
                    </NavLink>
                    <NavLink to="#" className="nav_link sign-up-btn">
                      SIGN IN
                    </NavLink>
                  </Nav>
                </Navbar.Collapse>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default Header;
