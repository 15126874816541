import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { BiSlider } from "react-icons/bi";

const Search = () => {
  const [activeTab, setActiveTab] = useState("searchHome");
  return (
    <>
      <div className="main-search">
        <Container>
          <div className="wrapper">
            <div className="tab-buttons">
              <button
                className={`tab-button ${
                  activeTab === "searchHome" ? "active" : ""
                }`}
                onClick={() => setActiveTab("searchHome")}
              >
                Search Homes
              </button>
              <button
                className={`tab-button ${
                  activeTab === "homeEstimate" ? "active" : ""
                }`}
                onClick={() => setActiveTab("homeEstimate")}
              >
                Estimate Home Value
              </button>
            </div>
            {activeTab === "searchHome" && (
              <div className="search-box">
                <p>Find Your Next Home</p>
                <form action="" className="search-box-form">
                  <InputGroup>
                    <Form.Control placeholder="Enter A City, School District Or Zip Code" />
                    {/* <Button variant="outline" className="filter custom_filter">
                      Filter <BiSlider />
                    </Button> */}
                  </InputGroup>
                  <Button className="custom_btn_2">GO</Button>
                </form>
              </div>
            )}

            {activeTab === "homeEstimate" && (
              <div className="search-box">
                <p>What's Your Home Worth</p>
                <form action="" className="search-box-form">
                  <InputGroup>
                    <Form.Control placeholder="Enter An Address" />
                  </InputGroup>
                  <Button variant="outline" className="filter custom_filter">
                    Unit
                  </Button>
                  <Button className="custom_btn_2">Estimate</Button>
                </form>
              </div>
            )}
          </div>
        </Container>
      </div>
      {/* <div className="main-search">
        <Container>
          <div className="wrapper">

            <div className="search-box">
              <form action="" className='search-box-form'>
                <InputGroup>
                  <Form.Control placeholder="Search Homes"/>

                <Button variant="outline" className='filter custom_filter' >Filter <BiSlider /></Button>
                </InputGroup>

                <Button className='custom_btn_2'>Search</Button>

              </form>
            </div>
          </div>
        </Container>
      </div> */}
    </>
  );
};

export default Search;
