import "./App.css";
import "./default.css";
import "./dashboard.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Contact from "./pages/Contact/Contact";
// import Testimonials from './pages/testimonials/Testimonials';
// import Blogs from './pages/blogs/Blogs';
// import SingleProduct from './pages/SingleProduct/SingleProduct';
// import Faqs from './pages/help-&-faq/Faqs';
// import PageNotFound from './pages/PageNotFound/PageNotFound';
// import Shop from './pages/shop/Shop';
// import Login from './pages/login/Login';
// import Register from './pages/register/Register';
// import Cart from './pages/cart/Cart';
// import CheckOut from './pages/checkout/CheckOut';
// import OrderTracking from './pages/orderTracking/OrderTracking';
// import OrderSearch from './pages/orderTracking/OrderSearch';
import ScrollToTop from "./components/ScrollToTop";
import Services from "./pages/Services/Services";
import Featured from "./pages/Featured/Featured";
import Careers from "./pages/Careers/Careers";
import HomeValueEstimator from "./pages/HomeValueEstimator/HomeValueEstimator";
import Agent from "./pages/Agent/Agent";
import RentalManagement from "./pages/RentalManagement/RentalManagement";
import MortgageResources from "./pages/MortgageResources/MortgageResources";
import TitleInsurance from "./pages/TitleInsurance/TitleInsurance";
import Construction from "./pages/Construction/Construction";
import Insurance from "./pages/Insurance/Insurance";
import SellingGuide from "./pages/SellingGuide/SellingGuide";
import Offices from "./pages/Offices/Offices";
import Referrals from "./pages/Referrals/Referrals";
import PropertySearch from "./pages/PropertySearch/PropertySearch";
import BuyingGuide from "./pages/BuyingGuide/BuyingGuide";
import MapSearch from "./pages/MapSearch/MapSearch";
import HouseSearch from "./pages/HouseSearch/HouseSearch";
import Communities from "./pages/Communities/Communities";
import CompassRose from "./pages/Compass Rose/CompassRose";

// ******** dashboard Links ********
// import SideBar from './admin/components/sidebar/SideBar';
// import Overview from './admin/pages/Overview';
// import Orders from './admin/pages/Orders';
// import Addresses from './admin/pages/Addresses';
// import Invoice from './admin/pages/invoice/Invoice';

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/featured" element={<Featured />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/HomeValueEstimator" element={<HomeValueEstimator />} />
          <Route path="/SellersGuide" element={<SellingGuide />} />
          <Route path="/Agent" element={<Agent />} />
          <Route path="/RentalManagement" element={<RentalManagement />} />
          <Route path="/MortgageResources" element={<MortgageResources />} />
          <Route path="/TitleInsurance" element={<TitleInsurance />} />
          <Route path="/Construction" element={<Construction />} />
          <Route path="/Insurance" element={<Insurance />} />
          <Route path="/Offices" element={<Offices />} />
          <Route path="/Referrals" element={<Referrals />} />
          <Route path="/PropertySearch" element={<PropertySearch />} />
          <Route path="/BuyersGuide" element={<BuyingGuide />} />
          <Route path="/MapSearch" element={<MapSearch />} />
          <Route path="/HouseSearch" element={<HouseSearch />} />
          <Route path="/Communities" element={<Communities />} />
          <Route path="/CompassRose" element={<CompassRose />} />
          {/* <Route path='/shop' element={<Shop />} />
          <Route path='/testimonials' element={<Testimonials />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/faqs' element={<Faqs />} />
          <Route path='/single-product/:id' element={<SingleProduct />} />
          <Route path='/checkOut' element={<CheckOut />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='login' element={<Login />} />
          <Route path='register' element={<Register />} />
          <Route path='single-orders/:id' element={<OrderTracking />} />
          <Route path='order-search' element={<OrderSearch />} /> 
          <Route path="*" element={<PageNotFound />} />*/}

          {/********* dashboard Links *********/}
          {/* <Route path="dashboard" element={<WithSidebar />}>
            <Route path="overview" element={<Overview />} />
            <Route path="orders" element={<Orders />} />
            <Route path="addresses" element={<Addresses />} />
          </Route>
          <Route path="invoice-show/:id" element={<Invoice />} /> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

// const WithSidebar = () => {
//   return (
//     <SideBar>
//       <Outlet />
//     </SideBar>
//   );
// };

export default App;
