import React from "react";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/sellbuy.jpg";
import { Col, Container, Row } from "react-bootstrap";
import OfficeImg from "../../images/featured01.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import MapComponent from "../../components/MapComponent";

const Offices = () => {
  return (
    <>
      <SectionHeader
        title="Offices"
        image={HeaderImage}
        PageLink="Offices"
      ></SectionHeader>
      <section className="offices">
        <div
          className="pages_header_title"
          data-aos="zoom-in-up"
          data-aos-duration="1000"
          data-aos-easing="linear"
        >
          <h1>OUR OFFICES</h1>
        </div>
        <Container>
          <MapComponent />
          <Row></Row>
          <Row>
            <div className="offices-map">
              <Col md={4}>
                <div className="offices-img">
                  <img src={OfficeImg} alt="#" />
                </div>
              </Col>
              <Col md={4}>
                <div className="offices-adress">
                  <FontAwesomeIcon icon={faLocationDot} />
                  <h2>Address</h2>
                </div>
                <div className="offices-adress-p">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                    tempus diam a quam semper gravida. Maecenas
                  </p>
                </div>
              </Col>
              <Col md={4}>
                <div className="offices-adress">
                  <FontAwesomeIcon icon={faPhone} />
                  <h2>Contact Information</h2>
                </div>
                <div className="offices-adress-p">
                  <p>(156)485-1456
                    <br />
                    Loremipsum@gmail.com
                    <br />
                  </p>
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Offices;
