import React, { useEffect, useState } from "react";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/sellbuy.jpg";
import { Container, Row } from "react-bootstrap";
import AOS from "aos";

const RentalManagement = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    comment: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data submitted:", formData);
  };
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <SectionHeader
        title="Rental and Community Management"
        image={HeaderImage}
        PageLink="Rental and Community Management"
      ></SectionHeader>
      <section className="RentalManagement">
        <Container>
          <div
            className="pages_header_title"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            <h1>Rental and Community Management</h1>
          </div>
          <div className="rental-management">
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum
            </p>
          </div>
        </Container>
        <Container>
          <Row>
            <div
              className="pages_header_title"
              data-aos="zoom-in-up"
              data-aos-duration="1000"
              data-aos-easing="linear"
            >
              <h1>Frequently Asked Questions</h1>
            </div>
            <div className="rental-management">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum
              </p>
            </div>
          </Row>
        </Container>
        <Container>
          <div
            className="pages_header_title"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            <h1>Contact Us</h1>
          </div>
          <div className="form_all_input">
            <form onSubmit={handleSubmit}>
              <div
                className="form_input"
                data-aos="zoom-in-up"
                data-aos-duration="1000"
                data-aos-easing="linear"
              >
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div
                className="form_input"
                data-aos="zoom-in-up"
                data-aos-duration="1000"
                data-aos-easing="linear"
              >
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div
                className="form_input"
                data-aos="zoom-in-up"
                data-aos-duration="1000"
                data-aos-easing="linear"
              >
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div
                className="form_input"
                data-aos="zoom-in-up"
                data-aos-duration="1000"
                data-aos-easing="linear"
              >
                <label htmlFor="comment">Comment</label>
                <textarea
                  rows="4"
                  cols="50"
                  id="comment"
                  name="comment"
                  value={formData.comment}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </Container>
      </section>
    </>
  );
};

export default RentalManagement;
