import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from "../images/sell.png";
import Image1 from "../images/buy.png";
import { Link } from "react-router-dom";

const SellBuy = () => {
  return (
    <>
      <div className="sell-buy-banner">
        <Container>
          <Row className="sell-buy-main">
            <div className="sell-buy-main-text">
              <h2>Solutions for All Your Real Estate Needs</h2>
              <p></p>
            </div>
            <Col lg="5">
              <Link to="/BuyersGuide">
                <div className="sell-buy-img">
                  <img src={Image} alt="" />
                  <p className="sell-buy-img-p">BUYING</p>
                </div>
              </Link>
            </Col>
            <Col lg="5">
              <Link to="/SellersGuide">
                <div className="sell-buy-img">
                  <img src={Image1} alt="" />
                  <p className="sell-buy-img-p">SELLING</p>
                </div>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SellBuy;
