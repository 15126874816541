import React from 'react'
import SectionHeader from '../../components/SectionHeader'
import HeaderImage from "../../images/sellbuy.jpg";

const CompassRose = () => {
    
    return (
        <>
            <SectionHeader
        title="Compass Rose"
        image={HeaderImage}
        PageLink="Compass Rose"
      ></SectionHeader>
      <div
          className="pages_header_title"
          data-aos="zoom-in-up"
          data-aos-duration="1000"
          data-aos-easing="linear"
        ></div>
        </>
    )
}

export default CompassRose