import React from "react";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/sellbuy.jpg";
import { Container, Row } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';

const HomeValueEstimator = () => {
  return (
    <>
      <SectionHeader
        title="Home Value Estimate"
        image={HeaderImage}
        PageLink="Home Value Estimate"
      ></SectionHeader>
      <Container>
        <div
          className="pages_header_title"
          data-aos="zoom-in-up"
          data-aos-duration="1000"
          data-aos-easing="linear"
        >
          <h1>Home Value Estimate</h1>
        </div>
        <div className="home-halue">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In tempus
            diam a quam semper gravida. Maecenas luctus sit amet magna sit amet
            semper. Nullam a mattis nibh. Ut vehicula eget erat in auctor. Lorem
            ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>
      </Container>
      <Container>
        <Row>
          <div
            className="pages_header_title"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            <h1>Frequently Asked Questions</h1>
          </div>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>What Lorem ipsum dolor sit amet?</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>What Lorem ipsum dolor sit amet?</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>What Lorem ipsum dolor sit amet?</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>What Lorem ipsum dolor sit amet?</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
      </Container>
    </>
  );
};

export default HomeValueEstimator;
