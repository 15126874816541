import React from "react";
import { Col, Container, NavLink, Row } from "react-bootstrap";

const Question = () => {
  return (
    <>
      <div className="main_questions">
        <Container>
          <Row>
            <Col lg="6" sm="12">
              <div className="question_form">
                <form action="">
                  <h1>For More Information?</h1>
                  <p></p>
                  <Col lg-12>
                    <input type="text" placeholder="Your Name" />
                  </Col>
                  <Col lg-12>
                    <input type="email" placeholder="Your Email" />
                  </Col>
                  <Col lg-12>
                    <input type="text" placeholder="Subject" />
                  </Col>
                  <Col lg-12>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="6"
                      placeholder="Your Message"
                    ></textarea>
                  </Col>
                  <Col lg-12>
                    <NavLink to="#" className="nav_link">
                      Send Message
                    </NavLink>
                  </Col>
                </form>
              </div>
            </Col>
            {/* <Col lg="6">
              <div className="question_form_content">
                <h5>Get In Touch</h5>
                <h3>CONTACT US</h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Numquam corporis ipsum officiis doloremque harum reprehenderit
                  enim veniam quo autem.
                </p>
                <div className="question_form_content_us">
                  <div className="contact_us_link">
                    <IoCallOutline />
                    <div>
                      <h6>Phone Number</h6>
                      <Link to="">615- 618-9414</Link>
                    </div>
                  </div>
                  <div className="contact_us_link">
                    <TfiEmail />
                    <div>
                      <h6>Email Address</h6>
                      <Link to="">chris@southcityRealtytn.com</Link>
                    </div>
                  </div>
                  <div className="contact_us_link">
                    <GrLanguage />
                    <div>
                      <h6>Website</h6>
                      <Link to="">Southcityrealtytn.com</Link>
                    </div>
                  </div>
                  <div className="contact_us_link">
                    <IoLocationOutline />
                    <div>
                      <h6>Address</h6>
                      <Link to="">1823 Stryker Pl. Brentwood, TN 37027</Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Question;
